import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import Suspension from 'src/components/suspension/suspension';

const SuspensionPage: React.FC = () => {
  return (
    <Layout>
      <PrivateRoute>
        <Suspension />
      </PrivateRoute>
    </Layout>
  );
};

export default SuspensionPage;
